<template>
  <div class="container">
    <!-- <p @click="test">Click me !!</p> -->
    <div v-if="is_error">
      <h1 style="color: red; margin-top: 10px">พบข้อผิดพลาด</h1>
      <h3>
        ผู้ใช้งานของคุณมีการลงทะเบียนด้วย LINE อื่นแล้ว
        กรุณาติดต่อผู้จัดการของคุณ
      </h3>
    </div>
    <div role="group" style="margin-top: 2rem" v-if="!is_getuser">
      <label for="input-live">กรอกเลขบัตรประชาชน :</label>
      <b-form-input
        id="input-live"
        v-model="idcard"
        :state="nameState"
        aria-describedby="input-live-help input-live-feedback"
        placeholder="เลขบัตรประชาชน"
        trim
        type="number"
      ></b-form-input>

      <!-- This will only be shown if the preceding input has an invalid state -->
      <b-form-invalid-feedback id="input-live-feedback">
        กรุณากรอกเลขบัตรประชาชน 13 หลัก
      </b-form-invalid-feedback>

      <!-- This is a form text block (formerly known as help block) -->
      <!-- <b-form-text id="input-live-help">Helper Text</b-form-text> -->
      <div v-if="nameState" style="margin-top: 2rem">
        <b-button variant="outline-primary" @click="subMitIdcard"
          >ยืนยัน</b-button
        >
      </div>
    </div>
    <div style="margin-top: 2rem" v-if="is_getuser && !is_done && !is_error">
      <h2>สวัสดีคุณ : {{ user_data.name }}</h2>
      <h2>username : {{ user_data.username }}</h2>
      <h1>กรุณาตั้งรหัสผ่าน</h1>
      <b-col sm="3">
        <label for="password1">รหัสผ่าน :</label>
      </b-col>
      <b-col sm="9">
        <b-form-input
          id="password1"
          type="password"
          v-model="password1"
        ></b-form-input>
      </b-col>
      <b-col sm="3">
        <label for="password2">ยืนยันรหัสผ่าน :</label>
      </b-col>
      <b-col sm="9">
        <b-form-input
          id="password2"
          type="password"
          v-model="password2"
        ></b-form-input>
      </b-col>
      <b-button
        variant="outline-primary"
        @click="subMitPassword"
        style="margin-top: 10px"
        >เปลี่ยนรหัสผ่าน</b-button
      >
    </div>
    <div style="margin-top: 2rem" v-if="is_done">
      <h2>เปลี่ยนรหัสผ่านสำเร็จ ท่านสามารถเข้าใช้งานระบบได้เลย !!</h2>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      best: "test",
      user: null,
      is_employee: false,
      is_check_line_id: false,
      // userId: "1312312312312",
      idcard: "",
      user_data: null,
      is_getuser: false,
      password1: "",
      password2: "",
      is_done: false,
      is_error: false,
    };
  },
  methods: {
    test() {
      alert("Hello world");
    },
    async checkUserid() {
      try {
        let get = await axios.get(
          `https://ktytech.com/prod/api/user/line?line_user_id=${this.user.userId}`
        );
        if (get?.data?.status == "error") {
          return alert(get?.data?.message);
        }
        if (get.data.status == "success" && get.data.data) {
          this.user_data = get.data.data;
          this.is_getuser = true;
        }
      } catch (error) {
        console.log(error);
        // alert("พบข้อผิดพลาด");
      }
    },
    async subMitIdcard() {
      try {
        let get = await axios.get(
          `https://ktytech.com/prod/api/user/line/idcard?idcard=${this.idcard}`
        );
        if (get?.data?.status == "error") {
          return alert(get?.data?.message);
        }
        if (get?.data?.data?.line_user_id) {
          this.is_error = true;
        } else {
          this.user_data = get.data.data;
          this.is_getuser = true;
        }
      } catch (error) {
        console.log(error);
        alert("พบข้อผิดพลาด");
      }
    },
    async subMitPassword() {
      try {
        if (!this.password1 || !this.password2) {
          return alert("กรุณากรอกรหัสผ่าน");
        }
        if (this.password1 != this.password2) {
          return alert("กรุณาระบุรหัสผ่านให้ตรงกัน");
        }
        if (!this.user.userId) {
          return alert("พบข้อผิดพลาด กรุณาปิดและเปิดใหม่อีกครั้ง");
        }
        let body = {
          line_user_id: this.user.userId,
          line_img_url: this.user.pictureUrl,
          password: this.password1,
          user_id: this.user_data.user_id,
        };
        let get = await axios.post(
          `https://ktytech.com/prod/api/user/line/password`,
          body
        );
        if (get?.data?.status == "error") {
          return alert(get?.data?.message);
        }
        this.password1 = "";
        this.password2 = "";
        this.is_done = true;
        alert("เปลี่ยนรหัสผ่านสำเร็จ");
      } catch (error) {
        console.log(error);
        alert("พบข้อผิดพลาด");
      }
    },
  },
  mounted() {
    liff
      .init({
        liffId: "1657335784-26lDwqY1",
      })
      .then(() => {
        if (liff.isLoggedIn()) {
          liff.getProfile().then((profile) => {
            console.log(profile);
            this.user = profile;
            this.checkUserid();
          });
        } else {
          liff.login();
        }
      });
    // this.checkUserid();
  },
  computed: {
    nameState() {
      return this.idcard.length == 13 ? true : false;
    },
  },
};
</script>
